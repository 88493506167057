<template>
  <ys-dialog
      title="创建组织"
      :visible.sync="innerVisible"
      @update:visible="updateVisible"
  >
    <div class="padding padding-large layout-vertical layout-center">
      <div>
        <div class="padding-vertical">
          <span>组织logo：</span>
          <ys-image-picker
              :image.sync="avatar"
              class="image-avatar"
          ></ys-image-picker>
        </div>
        <div class="padding-vertical">
          <span>组织名称：</span>
          <ys-input
              v-model="name"
              placeholder="请输入组织名称"
              :maxlength="32"
          ></ys-input>
        </div>
        <div class="font-color-danger" style="margin-left: 56px;" v-if="isNameExists"><i class="fas fa-caret-up"></i>
          组织名称已占用
        </div>
      </div>
    </div>
    <div slot="footer">
      <ys-button type="secondary" lighting @click="clickCancel">取消</ys-button>
      <ys-button lighting class="margin-left" @click="clickConfirm">创建</ys-button>
    </div>
  </ys-dialog>
</template>

<script>
import YsDialog from "@/components/wedigets/YsDialog";
import httpapi from "@/assets/javascript/httpapi";
import YsInput from "@/components/wedigets/YsInput";
import TextUtils from "@/assets/javascript/text-utils";
import YsButton from "@/components/wedigets/YsButton";
import YsImagePicker from "@/components/wedigets/YsImagePicker";

export default {
  name: "OrganizationAddDialog",
  mixins: [httpapi],
  components: {YsButton, YsInput, YsImagePicker, YsDialog},
  props: {
    visible: Boolean,
  },
  data() {
    return {

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 组织名称
       */
      name: null,

      /**
       * 组织头像
       */
      avatar: null,

      /**
       * 组织名称已占用
       */
      isNameExists: false,
      checkExistsTimeoutId: null,

      /**
       * 显示弹窗
       */
      innerVisible: false,
    }
  },
  watch: {
    visible: {
      handler(visible) {
        this.innerVisible = visible;
        this.loadingCode++;
        this.name = '';
        this.avatar = null;
      },
      immediate: true,
    },
    name: {
      handler() {
        this.startCheckNameExists();
      },
      immediate: true,
    }
  },
  methods: {
    clickCancel() {
      this.innerVisible = false;
      this.updateVisible();
    },
    clickConfirm() {
      if (!this.avatar) {
        this.$message.warning('请上传组织logo');
        return;
      }
      if (TextUtils.isBlank(this.name)) {
        this.$message.warning('请输入组织名称');
        return;
      }
      this.addOrganization();
    },
    updateVisible() {
      this.$emit('update:visible', this.innerVisible);
    },
    startCheckNameExists() {
      if (this.checkExistsTimeoutId) {
        clearTimeout(this.checkExistsTimeoutId);
      }
      this.checkExistsTimeoutId = setTimeout(() => {
        this.checkOrganizationNameExists();
        this.checkExistsTimeoutId = null;
      }, 500);
    },
    checkOrganizationNameExists() {
      if (!this.name) {
        this.isNameExists = false;
        return;
      }
      this.$reqGet({
        path: `/organization/exists/organization_name/${this.name}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.isNameExists = res.data;
          });
    },
    addOrganization() {
      if (this.busyLoadingCode === this.loadingCode) {
        this.$showBusy();
        return;
      }
      this.busyLoadingCode = this.loadingCode;
      this.$reqPost({
        path: `/organization/${encodeURIComponent(this.name)}/${this.avatar.id}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let organization = res.data;
            this.$message.success('创建成功');
            window.eventBus.$emit('update:organization', {id: organization.id});
            this.innerVisible = false;
            this.updateVisible();
          })
          .catch(() => this.$message.error('创建失败'))
          .complete(() => this.busyLoadingCode = 0);
    }
  }

}
</script>

<style scoped>

.image-avatar {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
</style>