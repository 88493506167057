<template>
  <div class="page layout-vertical">
    <div class="padding-horizontal-large padding-vertical font-size-large layout-inflexible">
      <back-button @click="$router.back()"></back-button>
      <span class="margin-left">组织管理</span>
      <ys-input
          class="input-search margin-left"
          placeholder="搜索组织"
          v-model="search"
          :maxlength="50"
      ></ys-input>
      <ys-button class="margin-left" @click="clickAddOrganization">+ 创建组织</ys-button>
    </div>
    <div class="layout-flexible padding-horizontal-large padding-vertical" style="overflow: hidden">
      <ys-infinite-scroll-view style="height: 100%;">
        <div
            class="list-item layout-horizontal layout-middle padding-horizontal-large padding-vertical font-size-medium"
            v-for="organization in organizationList"
            :key="organization.id"
            @click="clickOrganization(organization)"
        >
          <el-image
              class="layout-inflexible"
              fit="aspectFill"
              :src="$prepareImageUrl(organization.avatar)"
          ></el-image>
          <div class="margin-left layout-flexible">
            <div>{{ organization.name }}</div>
          </div>
          <div class="layout-inflexible font-color-light-placeholder"><i class="fas fa-chevron-right"></i></div>
        </div>
      </ys-infinite-scroll-view>
    </div>
    <organization-add-dialog
        :visible.sync="organizationAddDialogVisible"
    ></organization-add-dialog>
  </div>
</template>

<script>
import BackButton from "@/components/backbutton/BackButton";
import YsInput from "@/components/wedigets/YsInput";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import httpapi from "@/assets/javascript/httpapi";
import UserAvatar from "@/components/user/UserAvatar";
import YsButton from "@/components/wedigets/YsButton";
import OrganizationAddDialog from "@/pages/system/dialog/OrganizationAddDialog";

export default {
  name: "OrganizationList",
  mixins: [httpapi],
  components: {OrganizationAddDialog, YsButton, UserAvatar, YsInfiniteScrollView, YsInput, BackButton},
  data() {
    return {

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 组织列表
       */
      organizationList: [],
      pageNum: 1,
      totalPages: 0,
      pageSize: 20,

      /**
       * 搜索条件
       */
      search: null,
      searchTimeoutId: null,

      /**
       * 创建组织
       */
      organizationAddDialogVisible: false,

    }
  },
  watch: {
    search: {
      handler() {
        this.loadingCode++;
        this.startSearch();
      },
      immediate: true,
    }
  },
  mounted() {
    window.eventBus.$on('update:organization', this.handleOrganizationUpdate);
  },
  methods: {
    clickOrganization(organization) {
      this.$router.push({
        name: 'organization',
        query: {
          id: organization.id,
        }
      })
    },
    clickAddOrganization() {
      this.organizationAddDialogVisible = true;
    },
    handleOrganizationUpdate(event) {
      this.loadOrganization(event.id);
    },
    startSearch() {
      if (this.searchTimeoutId) {
        clearTimeout(this.searchTimeoutId);
      }
      this.searchTimeoutId = setTimeout(() => this.loadOrganizationList(), 500);
    },
    enterBusy() {
      if (this.busyLoadingCode === this.loadingCode) return false;
      this.busyLoadingCode = this.loadingCode;
      return true;
    },
    exitBusy() {
      this.busyLoadingCode = 0;
      return true;
    },
    loadOrganizationList(pageNum = 1) {
      if (!this.enterBusy()) {
        this.$showBusy();
        return;
      }
      this.$reqGet({
        path: `/organization/list/${pageNum}/${this.pageSize}/${this.search ? encodeURIComponent(this.search) : ''}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let organizationList = pageNum === 1 ? [] : this.organizationList;
            this.$appendAfter(organizationList, res.data.list);
            this.pageNum = pageNum;
            this.totalPages = res.data.pages;
            this.organizationList = organizationList;
          })
          .catch(() => this.$message.error('加载失败'))
          .complete(() => this.exitBusy());
    },
    loadMore() {
      if (this.pageNum < this.totalPages) this.loadOrganizationList(this.pageNum + 1);
    },
    loadOrganization(id) {
      this.$reqGet({
        path: `/organization/${id}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.$appendBefore(this.organizationList, [res.data]);
          })
          .catch(() => this.$message.error('加载失败'));
    }
  }
}
</script>

<style scoped>

.page {
  height: 100vh;
}

.input-search {
  width: 300px;
}

.el-image {
  height: 32px;
}

</style>